<template>
  <v-container>
    <v-card outlined rounded-pill max-width="100%" class="mx-auto pa-1 mb-2" >
          <v-layout wrap>
            <v-select dense outlined rounded class="ma-1" style="height: 40px; width: 200px;"
              id="company" 
              ref="company" 
              label="경영체" 
              v-model="company"
              :items="companyItems"
              :menu-props="{ top: false, offsetY: true }"
              no-data-text="자료(권한)이 없습니다."
              item-text="name"
              return-object
              @change="changeCompany()"
              @focus="comboCompanies()"
              clearable
              >
              <!-- <template slot="selection" slot-scope="data">
                    {{ data.item.name }}<span class="ml-1 items">{{data.item.code}}</span>
              </template> -->
              <template v-slot:item="slotProps" >
                    {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.code}}</span>
              </template>
            </v-select>
            <v-select dense outlined  rounded class="ma-1" style="height: 40px; width: 200px;" 
              id="farm" 
              ref="farm" 
              label="농장/사업장✽" 
              v-model="farm"
              :items="farmItems"
              :menu-props="{ top: false, offsetY: true }"
              no-data-text="자료(권한)이 없습니다."
              item-text="name"
              return-object
              @change="changeFarm()"
              @focus="comboFarms()"
              clearable
              >
              <!-- <template slot="selection" slot-scope="data">
                    {{ data.item.name }}<span class="ml-1 items">{{data.item.code}}</span>
              </template> -->
              <template v-slot:item="slotProps" >
                    {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.code}}</span>
              </template>
            </v-select>
            <v-select dense outlined  rounded class="ma-1" style="height: 40px; width: 200px;"
              id="building" 
              ref="building" 
              label="축사/건물/구역" 
              v-model="building"
              :items="buildingItems"
              :menu-props="{ top: false, offsetY: true }"
              no-data-text="자료(권한)이 없습니다."
              placeholder="선택않으면 농장/사업장전체를 모니터합니다"
              item-text="name"
              return-object
              @change="changeBuilding()"
              @focus="comboBuildings()"
              clearable
              >
              <!-- <template slot="selection" slot-scope="data">
                    {{ data.item.name }}<span class="ml-1 items">{{data.item.code}}</span>
              </template> -->
              <template v-slot:item="slotProps" >
                    {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.code}}</span>
              </template>
            </v-select>
            <v-btn class="elevation-5 ma-1 rounded-pill normal-btn" style="height: 40px; width: 110px"
              id="refreshList" 
              :loading="loading" 
              ref="refreshList" 
              @click="refreshList()">
              <img src="@/assets/icon_refresh.svg" alt="새로고침" />
              조회
            </v-btn>
          </v-layout>
        </v-card>

        <v-layout wrap>
          <v-card outlined class="control">현장제어</v-card>
          <v-card outlined color="#3a4f3f" class="control white--text">중앙제어</v-card>
          <v-card outlined color="#88191a" class="control white--text">확인필요</v-card>
          <v-card outlined color="#868686" class="control white--text">FC연결안됨</v-card>
          <v-row class="justify-end">
            <v-btn outlined class="mt-2 elevation-3 livecontrol_btn rounded-pill" @click="livecontrol()"> 출력 현황</v-btn>
            <v-btn outlined class="mt-2 elevation-3 seasonset_btn rounded-pill" @click="seasonset()"> 계절 설정</v-btn>
            <!-- <v-btn outlined class="mt-2 elevation-3 controlset_btn rounded-pill" @click="controlset()"> {{edit_mode}}</v-btn> -->
          </v-row>
        </v-layout>

    <v-card :loading="loading"  outlined class="mb-2" v-for="(building,b) in building_list" :key="b" >
        <div class="text_building">
          <span>{{building.building_name}}</span>
        </div>
        <div id="cardRoomInfo" class="ml-5 mt-5" >
          <div v-for="(room,r) in building.room_list" :key="r">
              <div id="roomBox" @click="clickRoombox(b,r)" :class="getRoomClass(room)">
                <div id="textArea">
                  <div class="text_name" >
                    <span class="text_name" :class="getTextClass(room)">
                      {{ room.room_name.substring(0,10) }}
                    </span>
                    <span class="text_status" v-show="room.control_mode > 0" :class="getTextClass(room)">
                      {{room.auto_mode}}
                    </span>
                  </div>
                  
                <div>
                  <div class="sensor_info">
                    <div v-for="info in getSensorInfo(room)" :key="info.type" :class="info.type + '_info'">
                      <img :src="info.icon" :class="[ {htemp_icon:info.type == 'feel', temp_icon:info.type !== 'feel'} ,getIconColor(room)]" >
                      <span class="text_number ml-2 mt-1" :class="getSensorInfoClass(room)">
                        {{ info.value }}
                      </span>
                      <span class="text_number_unit mr-3" :class="getSensorInfoClass(room)">
                        {{ info.unit }}
                      </span>
                      <!-- <div :class="'hover_' + info.type + '_info'" class="hover_info" v-show="info.value !== '-' ">
                        <div>{{ info.hoverText }}</div>
                      </div> -->
                    </div>
                  </div>

                  <div class="vent_info mb-3">
                    <div>
                      <div v-if="room.now_vent !== null" style="display:flex;">
                        <div class="vent" v-for="(vent,a) in getVentChInfo(room)" :key="a" :class=" getControlSet(room)">
                          <img :src="icon_fangreen" class="fan_vent_icon" v-if="vent == 0" :class="getControlIconSet(room)"/>
                          <img :src="icon_fangreen" class="fan_vent_icon test_vent" v-else :class="getControlIconSet(room)" :style="{ '--animation-duration' : (2 - (vent / 100 * 1.9))  + 's' }"/>

                        </div>

                        <div class="text_number mt-1 ml-3" style="font-size:12px;"  :class="getTextClass(room)"> 
                          <span> ( </span>
                          <span v-for="(vent,a) in getVentChInfo(room)" :key="a">{{ vent }} / </span>
                          <span> ) </span>
                        </div>
                      </div>

                      <div v-else>
                        <img :src="room.control_mode !== 0 ? icon_fanerror : icon_fanerror_gr" class="vent_icon" >
                        <span class=""> - </span>
                      </div>
                    </div>
                  </div>

                  <div class="controlinfo" :class="{ lightgray : room.control_mode === 0 }">
                    <div class="fc_info">
                      <div class="fc" :class="getControlSet(room)">
                        <img class="fanset_icon" :src="icon_set"/>
                      </div>
                      <div class="fc_temp">
                        <div class="text_temp color_black"> 목표온도 : {{room.set_temp}} </div>
                        <div class="text_temp color_black"> 온도편차 : {{room.dev_temp}}</div>
                      </div>

                      <span :style="[ room.lamp_mac ? { visibility : 'visible' } : { visibility :'hidden'} ]">
                        <v-icon
                          :color="room.lamp_on ? '#DBBA2E' : 'black'"
                          class="light"
                        >
                          mdi-lightbulb-outline
                        </v-icon>
                      </span>

                    </div>
  

                  <div class="start_date d_flex">
                    <div v-show="room.birthday">
                      <div class="text_birth"> 
                        <img class="icon_pig" :src="icon_pig"/>
                        <div style="margin-top:-25px; margin-left:20px">
                          <span class="ml-3">{{room.week_age}}</span>
                          <span class="text_birth_unit"> 주 </span>
                        </div>
                      </div>
                    </div>
                    <div class="controldate mr-2 ml-2 mt-1" >
                      <v-icon size="16">mdi-calendar</v-icon>
                      {{room.setdate && room.setdate.substr(0,10) || ""}}</div>
                    </div>
                </div>

                <v-card outlined color="transparent" class="controlsetting" v-show="room.setbtn" >
                  <div v-if="room.mac && room.control_mode > 0"> 
                    <v-card outlined color="transparent">
                      <v-row class="justify-center">
                        <v-btn outlined class="modify_btn rounded-pill mt-5 mr-5" @click="controlstart(building,room)">
                          제어변경
                        </v-btn>

                        <v-btn v-show="$getters.userInfo.grade < 'UT06'" outlined class="stop_btn rounded-pill mt-5" @click="controlstop(building,room)">
                          제어중단
                        </v-btn>
                      </v-row>
                    </v-card>
                  </div>

                  <div v-else-if="room.mac && room.control_mode === 0"> 
                    <v-card outlined color="transparent">
                      <v-row class="justify-center">
                        
                        <v-btn v-show="$getters.userInfo.grade < 'UT06'" outlined justify-center class="start_btn mt-5 rounded-pill" @click="controlstart(building,room)" >
                          제어시작
                        </v-btn>
                      </v-row>
                    </v-card>
                  
                  </div>

                  <div v-else-if="room.mac && room.control_mode === null"> 
                    <v-card outlined color="transparent">
                      <v-row class="justify-center">
                        <v-btn outlined justify-center class="error_btn mt-5 rounded-pill" @click="controlstart(building,room)">
                          제어확인
                        </v-btn>
                      </v-row>
                    </v-card>
                  </div>
                  
                </v-card> 
                </div>
              </div>
            </div>
          </div>
        </div>
    </v-card>

    <v-row class="justify-center">
      <v-btn v-if="$getters.userInfo.grade < 'UT06'" outlined class="allstop_btn rounded-pill elevation-5"
        :loading="loading"
        v-show="setbtn"
        @click="controlallstop()"> 
        제어 전체 중단
        </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import Apis from '@/api/apis';
import VueCookies from "vue-cookies";
import dateUtil from "@/utils/custom/dateProperty.js";
var setTime = 0;

export default {
  name: 'ControlSummary',

  async created(){
    Apis.pageCount({ //페이지뷰 카운터
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    },() => {  // 정상처리
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });

    await this.comboCompanies();
    await this.comboFarms();
    await this.comboBuildings();

    let cookie_info = VueCookies.get("controlcard_info"); // 이전 조회정보를 참조

    if (!this.isNull(this.$route.params)) {
      // console.log("Params : ", this.$route.params);
      this.company = this.$route.params.company;
      this.farm = this.$route.params.farm;
      this.building = this.$route.params.building;
    } else if (cookie_info) {
        this.company = cookie_info.company || {};
        this.farm = cookie_info.farm || {};
        this.building = cookie_info.building || {};
    } else {  // 이전 조회정보가 없을 경우 첫번째 농장/사업장 디폴트로 참조
        this.farm = this.farmItems[0];
        this.company = {code: this.farm.company_cd, name: this.farm.company_name};
    }
    this.refreshList();
  },

  data(){
    return{
      icon_fan: require("@/assets/barometer/icon_fan.svg"),
      icon_fan_white: require("@/assets/barometer/icon_fan_white.svg"),
      icon_controltemp: require("@/assets/barometer/icon_controltemp.svg"),
      icon_controltemp_white: require("@/assets/barometer/icon_controltemp_white.svg"),
      icon_fancontrol: require("@/assets/monitoring/fan_control.svg"),
      icon_temp: require("@/assets/monitoring/temp_icon.svg"),
      icon_hum: require("@/assets/monitoring/humidity_icon.svg"),
      icon_pig: require("@/assets/monitoring/pig.svg"),
      icon_vent: require("@/assets/monitoring/ventilation.svg"),
      icon_fangreen: require("@/assets/monitoring/fan_control_green.svg"),
      icon_alertfan: require("@/assets/monitoring/alert_fan.svg"),
      icon_alertfan_gray: require("@/assets/monitoring/alert_fan_gray.svg"),
      icon_alertfan_red: require("@/assets/monitoring/alert_fan_red.svg"),
      icon_set: require("@/assets/monitoring/set.svg"),
      icon_fanerror: require("@/assets/monitoring/fan_error.svg"),
      icon_fanerror_gr: require("@/assets/monitoring/fan_error_gr.svg"),

      icon_humantemp: require("@/assets/monitoring/human_temp.svg"),

      
      building_list:[],

      edit_mode: "제어 변경",
      company : {},
      companyItems : [],
      farm: {},
      farmItems: [],
      building: {},
      buildingItems: [],
      loading: false,
      setbtn: false,
      dialog:false,
    }

  },
  destroyed() {
    clearTimeout(setTime);
    // console.log("clearTimeout",setTime);
  },

  methods: {
    clickRoombox(b,r){
      // this.building_list[b].room_list[r].setbtn = !this.building_list[b].room_list[r].setbtn;
      this.building_list[b].room_list[r].setbtn = !this.building_list[b].room_list[r].setbtn;
      this.setbtn = this.building_list[b].room_list[r].setbtn
      this.edit_mode = this.setbtn ? "제어 확인" : "제어 변경";
    },
    refreshList(){
      this.$store.commit("resMessage","");
      if (!this.farm || !this.farm.code ) {
        alert("축사/건물/구역 또는 농장/사업장를 선택하세요");
        this.$refs.building.focus();
        return;
      }
      this.loading = true;

      Apis.listMonitoringControllers({
        farm_cd: this.farm && this.farm.code,
        building_cd: this.building && this.building.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
      } ,(res) => {  // 정상처리
        this.$store.commit("resMessage",res.message);
        // console.log(res.data);
        res.data.forEach((building) => {
            building.room_list.forEach((room) => {
              room.setbtn=false;
          })
        })
        this.building_list = res.data;

        this.loading = false;
        let cookie_info = {company: this.company, farm: this.farm, building: this.building };
        VueCookies.set("controlcard_info" ,cookie_info,"30d");

        // 현재 팬 출력 순차적 세팅
        setTimeout( () =>  this.getNowControllerValue(), 1000);

      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("listMonitoringControllers 호출 오류",err);
        this.loading = false;
      })
    },
    getNowControllerValue() {
      // console.log("갱신");
      this.building_list.forEach(((building) => {
          building.room_list.forEach(((room,index) => {
            setTimeout( async function() {
              // console.log("",element.mac,element.slave_id);
              await Apis.getNowControllerValue({
                mac: room.mac,
                slave_id: room.slave_id,
              } ,(res) => { 
                // console.log("res",res);
                // let res1= "_"
                room.now_vent =  { ch1: res.data.now_vent.substr(0,2).replace("_",0),ch2: res.data.now_vent.substr(3,2).replace("_",0),ch3: res.data.now_vent.substr(6,2).replace("_",0)} ;
                // room.now_vent =  { ch1: res1.substr(0,2).replace("_",0),ch2: res1.substr(3,2).replace("_",0),ch3: res1.substr(6,2).replace("_",0)} ;
                });
              },
              300 * index);
              }),)
              }),);
      // 현재 팬 출력 30초 마다 갱신
      setTime = setTimeout( () =>  this.getNowControllerValue(), 1000 * 60 );

    }, 
    async comboCompanies() {
      await Apis.comboCompanies({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.companyItems = [];
            for (let i in res.data) {
            this.companyItems.push({
              code: res.data[i].company_cd,
              name: res.data[i].company_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboCompanies API 호출 오류",err)
        }
      ) 
    },

    async changeCompany() {   // 하위 콤보 지우기;
      this.building = {};
      this.farm = {};

      if (this.company && this.company.code) {   
        await this.comboFarms();
        if (this.company) this.farm = this.farmItems[0];
      }
    },        

    async comboFarms() {
      await Apis.comboFarms({
        company_cd: this.company && this.company.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.farmItems = [];
            for (let i in res.data) {
            this.farmItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              code: res.data[i].farm_cd,
              name: res.data[i].farm_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboFarms API 호출 오류",err)
        }
      ) 
    },
    changeFarm() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      if (this.farm && this.farm.company_cd) {
        this.company = {code: this.farm.company_cd, name: this.farm.company_name};
        this.building = {};
      }
      this.buildingItems = [];
    },
    async comboBuildings() {
      await Apis.comboBuildings({
        company_cd:this.company &&  this.company.code || "",
        farm_cd: this.farm && this.farm.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.buildingItems = [];
            for (let i in res.data) {
            this.buildingItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              farm_cd: res.data[i].farm_cd, 
              farm_name: res.data[i].farm_name, 
              code: res.data[i].building_cd,
              name: res.data[i].building_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboBuildings API 호출 오류",err)
        }
      ) 
    },
    changeBuilding() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      if (this.building && this.building.company_cd) {
        this.company = {code: this.building.company_cd, name: this.building.company_name};
      }
      if (this.building && this.building.farm_cd) {
        this.farm = {code: this.building.farm_cd, name: this.building.farm_name};
      }
      if (this.building) {
      this.refreshList();
      }
    },

    controlallstop(){
      this.$store.commit("resMessage","");
      if (!confirm("중앙제어 중지 확인! \n 전체 돈방/위치의 제어가 마지막 설정 값 상태의 현장제어로 전환됩니다...\n 전체 돈방/위치의 중앙제어를 중지하겠습니까?")) { return; }
      else {
        this.loading=true;
        let room_list = [];
        this.building_list.forEach((building) => {
          building.room_list.forEach((room) => {
            if (room.control_mode > 0) {
              room_list.push(
                {
                  company_cd: building.company_cd,
                  farm_cd: building.farm_cd,
                  building_cd: building.building_cd, building_name: building.building_name,
                  room_cd: room.room_cd, room_name: room.room_name,
                  setdate: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"),
                  control_status: 0,
                  control_stand: "",
                  weight_range: 0,
                  daily_range_yn: "",
                  daily_range: 0,
                  birthday: "",
                  history: "일괄 중지",
                })
            }
          })
        });      

        Apis.stopRemoteControl({
          room_list: room_list,
        } ,(res) => {  // 정상처리
          if (res.result) {
            this.loading = false;
            this.refreshList();
            this.$store.commit("resMessage","전체 현장제어로 전환되었습니다");
          } else {
            this.$store.commit("resMessage",res.message);
            this.loading = false;
          }
        }).catch( (err) => {  // 개별 API 오류 처리 예제
          console.log("controlstop 호출 오류",err);
          this.loading = false;
        })
      }
    },

    controlstop(building,room){
      // console.log(building,room)
      this.$store.commit("resMessage","");
      if (!confirm("제어중지 확인 ! \n" +
                    room.room_name + "의 \n 제어가 마지막 설정 값 상태의 현장제어로 전환됩니다. \n 돈방/위치의 중앙제어를 중지하겠습니까?")) { return; }
      else {
        this.loading = true;

        Apis.stopRemoteControl({
          room_list: [{
                      company_cd: building.company_cd,
                      farm_cd: building.farm_cd,
                      building_cd: building.building_cd, building_name: building.building_name,
                      room_cd: room.room_cd, room_name: room.room_name,
                      setdate: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"),
                      control_status: 0,
                      control_stand: "",
                      weight_range: 0,
                      daily_range_yn: "",
                      daily_range: 0,
                      birthday: "",
                      history: "단일 중지",
                    }]
        } ,(res) => {  // 정상처리
          if (res.result) {
            // room.control_mode = 0;
            this.refreshList();
            this.loading = false;
            this.$store.commit("resMessage","현장제어로 전환되었습니다");
          } else {
            this.$store.commit("resMessage",res.message);
            this.loading = false;
          }
        }).catch( (err) => {  // 개별 API 오류 처리 예제
          console.log("controlstop 호출 오류",err);
          this.loading = false;
        })
      }
    },
    
    controlstart(building,room){
      // console.log(room);
      this.$router.push({
        name: "ControlSet",
        params: { 
          building: building.building_cd,
          room: room.room_cd
          }
      });
    },
    seasonset(){
      // console.log(room);
      this.$router.push({
        // name: "ControlTable",
        name: "ControlTable2",
        params: { 
          company: this.company,
          farm: this.farm,
          building: this.building,
          }
      });
    },
    livecontrol() {
      this.$router.push({
        name: "SettingControl",
        params: {
          company: this.company,
          farm: this.farm,
          building: this.building
        }
      });
    },

    // 스타일링
    getRoomClass(room){
      return {
        white: room.control_mode == 0,
        green: room.control_mode > 0,
        red: room.control_mode === null && room.mac !== null,
        gray: room.mac === null,
      };
    },
    getTextClass(room){
      return{
        color_gold : room.control_mode > 0, 
        color_black: room.control_mode == 0,
        color_white: room.control_mode == null,
        color_error: room.mac !== null && room.control_mode == null
      }
    },
    getSensorInfoClass(room){
      return{
        color_black: room.control_mode == 0,
        color_white: room.control_mode > 0 ,
        color_error: room.mac !== null && room.control_mode == null
      }
    },
    getIconColor(room){
      return{
        white_color:room.control_mode !== 0, 
        green_color: room.control_mode === 0
      }
    },
    getSensorInfo(room){
      return[
        {
          type: 'temp',
          value: room.temp,
          unit: '℃',
          icon: this.icon_temp,
          hoverText: '온도',
        },
        {
          type: 'hum',
          value: room.hum,
          unit: '%',
          icon: this.icon_hum,
          hoverText: '습도',
        },
        {
          type: 'feel',
          value: room.feel,
          unit: '℃',
          icon: this.icon_humantemp,
          hoverText: '체감온도',
        },
      ]
    },
    getVentChInfo(room){
      return[
        room.now_vent.ch1,
        room.now_vent.ch2,
        room.now_vent.ch3
      ]
    },
    getControlSet(room){
      return{
        lightgray:room.control_mode >= 1,
        green: room.control_mode == 0,
        red:room.control_mode == null && room.mac !== null,
        gray:room.mac == null
      }
    },
    getControlIconSet(room){
      return{
        green_color:room.control_mode !== 0, 
        white_color: room.control_mode === 0
      }
    }
   }
  }
</script>


<style lang="scss" scoped >
.d_flex{
  display: flex;
}
.control{
  width:70px;
  text-align: center;
  height:25px;
  margin-bottom: 5px;
  margin-left: 5px;
  padding-top: 2px;
  font-size:12px !important;
  font-weight: bold;
}
#cardRoomInfo {
  position: relative;
  width: calc(100% - 70px);
  display: flex;
  flex-wrap: wrap;
}
#roomBox {
  width: 250px;
  min-height: auto;
  margin: 2px 20px 20px 0px;
  margin-bottom:20px;
  padding-bottom:18px;
  border: solid 1px #57715d !important;
  border-radius: 5px;
  box-shadow: 3px 5px 5px 0px rgba(0, 0, 0, 0.2);
  text-align: center;
}
.text_building {
  margin-left:20px;
  margin-top:10px;
  margin-bottom:-20px;
  font-size: 18px !important;
  font-weight: bold !important;
  letter-spacing: -0.6px;
  text-align: left !important;
  color: #3a4f3f;
}
.livecontrol_btn{
  margin-top:15px;
  margin-bottom:20px;
  margin-right:5px;
  color:#7f7d7d;
  width:110px;
  height: 30px !important;
  font-weight: bold;
  font-size:14px !important;
}
.controlset_btn{
  margin-top:15px;
  margin-bottom:20px;
  // margin-right:24px;
  margin-left:5px;
  color:#88191a;
  width:120px;
  height: 20px;
  font-weight: bold;
  font-size:14px !important;
}
.seasonset_btn{
  margin-top:15px;
  margin-bottom:20px;
  background-color: #8b8b8b;
  margin-right:24px;
  color: white;
  width:110px;
  height: 30px !important;
  font-weight: bold;
  font-size:14px !important;
}
.text_status {
  position: relative;
  left: 8px;
  margin-top: 15px;
  font-size: 15px !important;
  font-weight: bold !important;
  letter-spacing: -0.39px;
  text-align: right !important;
  color: #e4e4e4
}
.text_name {
  position: relative;
  left: 5px;
  font-size: 20px !important;
  font-weight: bolder !important;
  letter-spacing: -2.0px;
  text-align: left !important;
  color: white;
  margin-bottom: 5px;
  margin-top:20px;
}
#textArea > div {
  font-size: 20px;
  font-weight: 500 !important;
  letter-spacing: -0.6px;
  line-height:15px;
  margin-left:7px;
  text-align: left;
  margin-right: 8px;
  color: white;
}
// .text_number {
//   font-size: 18px;
//   margin-left:3px;
//   letter-spacing: -0.6px;
//   // margin-top:5px
// }
// .text_number_unit {
//   height: 35px;
//   font-size: 15px;
//   margin-left: 2px;
//   line-height: 40px;
// }
.box_temp_count {
  display: flex;
  align-items: center;
  margin-top:10px;
  margin-left: 6px;
  /* background-color: coral; */
}
.normal-btn{
  align-content: center !important;
  color:white !important;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}
.white {
  border: solid 2px #3a4f3f;
}
.green {
  background-color: #3a4f3f !important;
}
.red {
  background-color: #88191a !important;
}
.gray {
  background-color: #868686 !important;
}
.lightgray{
  background-color: #ececec !important;
}
.gold{
  background-color: #f1f595 !important;
}
.color_black {
  color: #3a4f3f !important;
}
.color_gold {
  color: #f1f595 !important;
}
.color_gray {
  color: #868686 !important;
}
.color_error {
  color: #88191a !important;
}
.color_white{
  color:white;
}
.white_color{
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}
.green_color{
  filter: invert(28%) sepia(33%) saturate(338%) hue-rotate(82deg) brightness(85%) contrast(87%);
}

.box_feel{
  margin-top:-15px;
}
.box_temp{
  align-items: center;
  margin-top:-57px;
  margin-left: -4px;
}
.value_temp{
  font-size: 12px;
  margin-left: 45px;
}
.value_hum{
  font-size: 12px;
}
.light{
  margin-left:10px;
  margin-top:6px;
}
.value_feel{
  font-size:12px;
  margin-left: 40px;
}
.fc_info{
  display:flex;
  margin-bottom: 3px;
  // padding:5px 5px 5px 5px;
}
.text_fc{
  font-size:17px !important;
  padding:10px;
}
.text_temp{
  font-size:15px !important;
}
.fc_temp{
  padding:5px;
}
.text_birth{
  font-size:13px !important;
  color:#3a4f3f;
  padding-left:6px;
}
.text_birth_unit{
  font-size:15px;
  color:#3a4f3f;
}
.start_date{
  display:flex;
  justify-content: space-between; 
  padding-bottom: 10px;
}
.controldate{
  color:#7b7b7b;
  font-size:13px !important;
}
.controlinfo{
  background-color:white;
  border-radius: 5px;
  border-color:transparent;
}
.modify_btn{
  background-color: white !important;
  color:#3a4f3f !important;
  font-size:15px;
  border-color:transparent;
  font-weight: bolder !important;
}
.stop_btn{
  background-color: #88191a !important;
  color:white !important;
  font-size:15px;
  border-color:transparent;
  font-weight: bolder !important;
}
.start_btn{
  background-color: #3a4f3f !important;
  color:white;
  border-color:transparent;
  font-size:15px;
  font-weight: bolder !important;
}
.error_btn{
  background-color: white !important;
  color: #3a4f3f !important;
  font-size:15px;
  font-weight: bolder !important;
  border-color:transparent;
}
.allstop_btn{
  color:#88191a !important;
  width:200px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top:30px;
  font-size:18px;
}
.fc{
  width:22px;
  margin:8px 3px 8px 6px;
  padding:4px 5px 4px 3.1px;
  border-radius: 4px;
}
.vent{
  width:20px;
  margin:0px 0px 0px 5px;
  // padding:4px;
  border-radius: 4px;
}

.fan_vent_icon{
  width:15px;
  margin-top:2px;
  margin-left:2px;
}
.fan_icon{
  width:15px;
}
.vent_icon{
  width:22px;
  margin-left:3px;
}
.fanset_icon{
  width:15px;
}
.icon_pig{
  width:25px;
}
.set_icon{
  width:15px;
}
.temp_icon{
  width:11px;
}
.htemp_icon{
  width:22px;
}
.sensor_info, .vent_info{
  display:flex;
  align-items: center;
  margin-top:10px;
  margin-bottom: 5px;
}
.text_number {
  font-size: 18px;
  letter-spacing: -0.6px;
}
.text_number_unit {
  font-size: 12px;
  margin-bottom:5px;
  margin-left: 2px;
}

//팬출력
@keyframes rotate_image {
  100% {
    transform: rotate(360deg);
  }
}

.test_vent { animation: rotate_image var(--animation-duration) linear infinite; transform-origin: 50% 50%;}

.temp_info, .hum_info, .feel_info{
  display:flex;
}
.hover_info , .hover_temp_info{
  z-index:1 !important;
  display: none;
  position: absolute;
  width: 50px;
  padding: 10px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  border: 2px solid #3a4f3f;
  background-color: #F2FCF4;
  color: #3a4f3f;
  font-size: 15px;
  font-weight:600;
  margin-top:60px;
  box-shadow: 0 2px 2px -1px rgb(255, 255, 255);
}
.hover_hum_info{
  margin-left:75px;
}
.hover_feel_info{
  margin-left:150px;
  width:75px !important;
}
.temp_info:hover + .hover_temp_info{
  display: block;
  transition: all 200ms ease-in-out;
}

.hum_info:hover + .hover_hum_info{
  display: block;
  transition: all 200ms ease-in-out;
}
.feel_info:hover + .hover_feel_info{
  display: block;
  transition: all 200ms ease-in-out;
}
@media screen and (max-width: 768px){
  #cardRoomInfo{
  left:20px;
  justify-content: center;
  }
}
.items {
  color: #a3a1a1;
  font-size: 14px;
  // font-weight: bold;
  text-align: left;
  letter-spacing: -1.2px;
}

</style>